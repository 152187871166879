<div class="show-button">
  <div
    class="filter-button-group mt-0"
    *ngIf="
      filter['layout'] !== 'collection_offcanvas_filter' &&
      filter['layout'] !== 'collection_no_sidebar'
    "
  >
    <div class="filter-button d-inline-block d-lg-none">
      <a (click)="openFilter(true)"
        ><i class="ri-filter-2-fill"></i> {{ "Danh Mục" | translate }}</a
      >
    </div>
  </div>
  <div class="top-filter-menu">
    <div class="category-dropdown">
      <a
        href="javascript:void(0)"
        class="filter-offcanvas-title"
        (click)="openOffCanvasMenu()"
        *ngIf="filter && filter['layout'] == 'collection_offcanvas_filter'"
      >
        <i class="ri-filter-2-fill"></i> {{ "Danh Mục" | translate }}
      </a>
      <div class="text-content me-2">Sắp xếp theo:</div>
      <div class="sort-by-group">
        <ul class="list-inline">
          <li class="list-inline-item" *ngFor="let sort of sorting; let i = index">
            <app-button
            [label]="sort.label"
            id="btn-sort-{{i}}"
            [class]="sort.selected ? 'btn btn-sm btn-success' : 'btn btn-sm btn-outline-success'"
            (click)="sortByButton(sort)"
            [type]="'button'"
            style="margin-bottom: 4px;"
            >
            <i class="{{sort.iconClasses}}"></i>
            <span>{{sort.label}}</span>
          </app-button>
          </li>
        </ul>
        
      </div>
    </div>
    <div class="grid-option d-none d-md-block">
      <ul>
        <li
          class="three-grid"
          [class.active]="selectedGrid == 'collection_3_grid'"
        >
          <a href="javascript:void(0)" (click)="grid('collection_3_grid')">
            <img src="assets/svg/grid-3.svg" alt="sort" />
          </a>
        </li>
        <li
          class="grid-btn d-xxl-inline-block d-none"
          [class.active]="selectedGrid == 'collection_4_grid'"
        >
          <a href="javascript:void(0)" (click)="grid('collection_4_grid')">
            <img
              src="assets/svg/grid-4.svg"
              class="d-lg-inline-block d-none"
              alt="sort"
            />
            <img
              src="assets/svg/grid.svg"
              class="img-fluid d-lg-none d-inline-block"
              alt="sort"
            />
          </a>
        </li>
        <li
          class="grid-btn d-xxl-inline-block d-none"
          [class.active]="selectedGrid == 'collection_5_grid'"
        >
          <a href="javascript:void(0)" (click)="grid('collection_5_grid')">
            <img
              src="assets/svg/grid-5.svg"
              class="d-lg-inline-block d-none"
              alt="sort"
            />
            <img
              src="assets/svg/grid.svg"
              class="img-fluid d-lg-none d-inline-block"
              alt="sort"
            />
          </a>
        </li>
        <!-- <li
          class="list-btn"
          [class.active]="selectedGrid == 'collection_list_view'"
        >
          <a href="javascript:void(0)" (click)="grid('collection_list_view')">
            <img src="assets/svg/list.svg" alt="sort" />
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</div>

