import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '@/app/shared/services/common.service';
import { Filter } from '@/app/shared/interface/common.interface';

@Component({
  selector: 'app-collection-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class CollectionSidebarComponent {
  @Input() filter: any;
  @Input() skeletonLoader: any = false;
  @Output() addFilter: EventEmitter<any> = new EventEmitter();
  @Output() closeSideBar: EventEmitter<any> = new EventEmitter();

  public categories: any[];
  public filters: Filter[] = [];

  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.commonService.getCategories.subscribe({
      next: (data: any) => {
        this.categories = data;
      },
    });
  }

  // removeFilters(filter: Filter) {
  //   if (filter) {
  //     this.filters = this.filters.filter(f => f.index != filter.index);
  //     $(`#btn_${filter.index}`).toggleClass("active")
  //   }
  //   else {
  //     this.filters.forEach((f: any) => {
  //       if ($(`#btn_${f.index}`).hasClass("active")) {
  //         $(`#btn_${f.index}`).toggleClass("active");
  //       }
  //     })
  //     this.filters = [];
  //   }
  // }

  closeCanvasMenu() {
    this.closeSideBar.emit(false);
  }
}
