import {
  Component,
  Inject,
  Input,
  PLATFORM_ID,
  TransferState,
  afterNextRender,
} from '@angular/core';
// import { Observable } from 'rxjs';
import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';
import {
  Product,
  ProductInfoType,
  ProductModel,
} from '@/app/shared/interface/product.interface';
import * as data from '@/app/shared/data/owl-carousel';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { TOKEN } from '../../services/auth.service';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss'],
})
export class RelatedProductsComponent {
  // @Select(ProductState.relatedProducts) relatedProduct$: Observable<Product[]>;

  @Input() product: Product;
  @Input() title: string;
  @Input() searchType: string;

  public relatedproducts: Product[] = [];
  public productSimilartSlider = data.productSimilartSlider;

  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('resize', () => {
        this.changeItemsPerSlide();
      });
    }
  }

  ngOnInit() {
    let searchTerm = '';
    if (this.product.ingredients && this.searchType == 'ingredients') {
      //searchTerm = this.product.ingredients;
      this.getRelatedProducts(searchTerm);
    } else if (
      this.product.manufacturer &&
      this.searchType == 'manufacturers'
    ) {
      //searchTerm = this.product.manufacturer.name;
      this.getRelatedProducts(searchTerm);
    }

    this.changeItemsPerSlide();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('product')) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  changeItemsPerSlide() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 756) {
        this.productSimilartSlider.responsive![0].items = 2;
      } else {
        this.productSimilartSlider.responsive![0].items = 6;
      }
    }
  }

  getRelatedProducts(searchTerm: string) {
    const body = {
      limit: 10,
      //searchTerm: searchTerm
    };

    const token = this.transferState.get(TOKEN, '');

    if(token){
      this.productService.getProductsForMember(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.relatedproducts = res.products;
            this.productSimilartSlider.items = res.total;
          }
        },
      });
    }
    else{
      this.productService.getProductsForGuest(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.relatedproducts = res.products;
            this.productSimilartSlider.items = res.total;
          }
        },
      });
    }
  }

  // ngOnChanges() {
  //   if (this.product?.related_products && Array.isArray(this.product?.related_products)) {
  //     this.relatedProduct$.subscribe(products => {
  //       this.relatedproducts = products.filter(product => this.product?.related_products?.includes(product?.id));
  //     });
  //   }

  // }
}
