import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '@/environments/environment';

const defaultSEO = {
    title: 'Giá Thuốc Tốt - “Nhanh – Hiệu quả – Tiện lợi”',
    metaTitle: 'Giá Thuốc Tốt - “Nhanh – Hiệu quả – Tiện lợi',
    description: 'Giá Thuốc Tốt - “Nhanh – Hiệu quả – Tiện lợi',
    keyword: 'Thuốc, Dược phẩm, Mỹ phẩm, Thiết bị y tế, Dược Vương',
    image: '',
    slug: '',
}

@Injectable({
    providedIn: "root",
})
export class SeoService {

    siteName: string = 'Công Ty Cổ Phần Thương Mại Dược Vương';
    websiteTpye: string = 'website';

    constructor(
        private meta: Meta,
        private title: Title,
        public router: Router,
        @Inject(DOCUMENT) private document: Document
    ) { }

    generateTags(config: any) {
        config = {
            title: defaultSEO.title,
            metaTitle: defaultSEO.metaTitle,
            description: defaultSEO.description,
            keyword: defaultSEO.keyword,
            image: defaultSEO.image,
            slug: defaultSEO.slug,
            ...config
        };
        this.title.setTitle(config.title);
        this.meta.updateTag({ name: 'title', content: config.metaTitle });
        this.meta.updateTag({ name: 'description', content: config.description });
        if (config.keyword) {
            this.meta.updateTag({ name: 'keywords', content: config.keyword });
        }
        this.meta.updateTag({ name: 'twitter:url', content: environment.site + this.router.url });
        this.meta.updateTag({ name: 'twitter:title', content: config.metaTitle });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:card', content: "summary_large_image" });
        // Canonical
        let canonical = this.document.querySelector('link[rel=canonical]') ? this.document.querySelector('link[rel=canonical]') : this.document.createElement('link');
        if (canonical) {
            canonical.setAttribute('rel', "canonical");
            canonical.setAttribute('href', environment.site + this.router.url);
        }

        this.meta.updateTag({ property: 'og:site_name', content: this.siteName });
        this.meta.updateTag({ property: 'og:url', content: environment.site + this.router.url });
        this.meta.updateTag({ property: 'og:title', content: config.metaTitle });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:type', content: this.websiteTpye });
        if (!config.image) {
            this.meta.addTag({ name: 'twitter:image', content: config.image });
            this.meta.addTag({ property: 'og:image', content: config.image });
            this.meta.addTag({ property: 'og:image:alt', content: config.metaTitle });
            this.meta.addTag({ property: 'og:image:secure_url', content: config.image });
            this.meta.addTag({ property: 'og:image:secure', content: config.image });
            this.meta.addTag({ property: 'og:image:url', content: config.image });
            this.meta.addTag({ property: 'og:image:width', content: '300' });
            this.meta.addTag({ property: 'og:image:height', content: '300' });
        }
        else {
            this.meta.updateTag({ name: 'twitter:image', content: config.image });
            this.meta.updateTag({ property: 'og:image', content: config.image });
            this.meta.updateTag({ property: 'og:image:alt', content: config.metaTitle });
            this.meta.updateTag({ property: 'og:image:secure_url', content: config.image });
            this.meta.updateTag({ property: 'og:image:secure', content: config.image });
            this.meta.updateTag({ property: 'og:image:url', content: config.image });
            this.meta.updateTag({ property: 'og:image:width', content: '300' });
            this.meta.updateTag({ property: 'og:image:height', content: '300' });
        }
    }
}