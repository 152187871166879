<div class="pickup-box">
  <div class="product-info">
    <div class="col-xxl-12 col-xl-12 col-lg-12">
      <div *ngFor="let info of product.productInfo">
        <div
          class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"
          *ngIf="info.display"
        >
          <div class="col-xl-3 product-info-label">
            <p>{{ info.name }}</p>
          </div>
          <div class="col-xl-9 product-info-content">
            <p *ngIf="info.path; else value">
              <button
                class="border-0 bg-transparent theme-color p-0"
                (click)="goto(info.path)"
              >
                {{ info.value }}
              </button>
            </p>
            <ng-template #value>
              <p>{{ info.value || "Đang cập nhật" }}</p>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"
      >
        <div class="col-xl-3 product-info-label"></div>
        <!-- <div
          class="col-xl-9 product-info-content"
          *ngIf="product.wholesalePriceInfo"
        >
          <app-button
            [class]="'btn btn-sm text-theme border border-success'"
            [type]="'button'"
          >
            <span>Mua số lượng từ {{ product.wholesalePriceInfo.minQuantity }} giá
              {{ product.wholesalePriceInfo.wholesalePrice | currencySymbol }}</span>
          </app-button>
        </div> -->
        <div *ngIf="product.labels.length > 0 && isAuthenticated" class="mb-2">
          <div class="sort-by-group">
            <ul class="list-inline">
              <ng-container *ngFor="let l of product.labels; let i = index">
                <li
                  *ngIf="l.slug"
                  class="list-inline-item"
                >
                  <app-button
                    [label]="l.name"
                    id="btn-sort-{{ i }}"
                    [class]="'btn btn-sm text-theme border border-success'"
                    [type]="'button'"
                    (click)="redirectPage(l.slug)"
                  >
                    <span class="mobile-product-label">{{ l.name }}</span>
                  </app-button>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div
        *ngIf="product.stockStatus < 8 && product.basePrice > 0"
        class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"
        style="align-items: center"
      >
        <span class="col-xl-3 product-info-label text-start"> Chọn số lượng </span>
        <div
          class="col-xl-9 product-info-content d-flex align-items-center gap-4"
        >
          <div class="cart_qty qty-box product-qty mt-0">
            <span class="input-group" *ngIf="isAuthenticated">
              <app-counter
                *ngIf="cartItem"
                [cartItem]="cartItem"
                style="width: 100%"
              ></app-counter>
            </span>
            <span class="input-group" *ngIf="!isAuthenticated">
              <app-counter
                *ngIf="cartItem"
                [cartItem]="cartItem"
                style="width: 100%"
              ></app-counter>
            </span>
          </div>
          <a
            title="Thêm vào sản phẩm yêu thích"
            href="javascript:void(0)"
            class="wishlist-button {{
              !inWishList ? '' : 'active'
            }} d-flex align-items-center justify-content-center"
            (click)="addToWishlist(product.productID)"
            *ngIf="isAuthenticated"
          >
            <i
              class="wishlist-icon {{
                !inWishList ? 'ri-heart-line' : 'ri-heart-fill fill'
              }} ri-lg"
              style="margin-top: 2px"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
