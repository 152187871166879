<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

<section class="product-section">
  <!-- <app-loader
    class="loader-wrapper app-loader"
    *ngIf="commonService.showLoader"
  ></app-loader> -->
  <div class="container-fluid-lg">
    <div class="row gap-4">
      <div class="col-xxl-12 col-xl-12 col-lg-12 mobile-product-detail">
        <div class="row g-4 p-4 bg-white rounded-4 mobile-product-detail">
          <div class="col-xl-4">
            <div class="product-left-box">
              <div class="row g-2">
                <div class="col-12">
                  <div class="product-main-1 no-arrow">
                    <div class="product-label-group"></div>
                    <owl-carousel-o
                      [options]="productMainThumbSlider"
                      #owlCar
                      class="product-slide"
                      (changed)="getPassedData($event)"
                    >
                      <ng-container *ngIf="product">
                        <ng-container
                          *ngFor="let image of product.imageUrls; index as i"
                        >
                          <ng-template carouselSlide [id]="i.toString()">
                            <a
                              href="javascript:void(0)"
                              (click)="openModal(i, productDetailImageModal)"
                            >
                              <div class="slider-image">
                                <img
                                  [src]="
                                    image ? image : 'assets/images/product.png'
                                  "
                                  class="img-fluid min-height"
                                  alt="{{ product.name }}"
                                />
                              </div>
                            </a>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </owl-carousel-o>
                  </div>
                </div>
                <div
                  class="col-12"
                  *ngIf="product && product.imageUrls.length > 1"
                >
                  <div
                    class="bottom-slider-image left-slider no-arrow slick-top"
                  >
                    <owl-carousel-o [options]="productThumbSlider">
                      <ng-container
                        *ngFor="let image of product.imageUrls; let i = index"
                      >
                        <ng-template carouselSlide [id]="i.toString()">
                          <div>
                            <div
                              class="slider-image"
                              [class.active]="
                                i.toString() == activeSlide.toString()
                              "
                            >
                              <button
                                class="more-images"
                                *ngIf="product.imageUrls.length > 4 && i == 3"
                                (click)="openModal(i, productDetailImageModal)"
                              >
                                Xem thêm {{ product.imageUrls.length - 4 }} ảnh
                              </button>
                              <img
                                [src]="
                                  image ? image : 'assets/images/product.png'
                                "
                                style="aspect-ratio: 1"
                                class="img-fluid img-slider"
                                alt="{{ product.name }}"
                                (click)="setSlide(i.toString(), owlCar)"
                              />
                            </div>
                          </div>
                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </div>
                </div>
                <!-- 24h -->
               <!-- <div class="col-12 pt-3">
                  <span class="align-items-center gap-1 d-none d-lg-flex d-xl-flex justify-content-center" style="color: #6A6A6A;">
                    <img src="assets/svg/view 1.svg" />
                    <h6 class="font-12 fw-normal text-lowercase">{{ views }} lượt xem trong 24h</h6> x
                  </span>
                </div> -->
                <!-- Flash Sale -->
                <div class="col-12"></div>
              </div>
            </div>
          </div>
            <div class="col-xl-8 mobile-product-detail">
              <div class="right-box-contain">
                <app-product-contain
                  *ngIf="product"
                  [product]="product"
                  [option]="option"
                  [owlCar]="owlCar"
                  [isAuthenticated]="isAuthenticated"
                ></app-product-contain>
                <!-- <app-product-action 
                  *ngIf="product" 
                  [product]="product">
                </app-product-action> -->
                <!-- <app-product-delivery-information
                  *ngIf="product"
                  [product]="product"
                ></app-product-delivery-information> -->
                <!-- <app-payment-option
                  [product]="product"
                  [option]="option"
                ></app-payment-option> -->
                <!-- <app-product-social-share
                  *ngIf="product"
                  [product]="product"
                  [option]="option"
                ></app-product-social-share> -->
              </div>
            </div>
          
          <!-- <div class="col-12 related-product-2" *ngIf="product">
            <app-product-bundle [product]="product"></app-product-bundle>
          </div> -->
        </div>
      </div>

      <div class="col-12 p-4 bg-white rounded-4">
        <app-product-details-tabs
          *ngIf="product"
          [product]="product"
        ></app-product-details-tabs>
      </div>
      <!-- <div class="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block">
        <app-product-details-sidebar
          [product]="product"
        ></app-product-details-sidebar>
      </div> -->
    </div>
  </div>
</section>

<section class="product-list-section section-b-space" *ngIf="product">
  <app-related-products
    [product]="product"
    [title]="'Sản Phẩm Cùng Hoạt Chất'"
    [searchType]="'ingredients'"
  >
  </app-related-products>
</section>

<section class="product-list-section section-b-space" *ngIf="product">
  <app-related-products
    [product]="product"
    [title]="'Sản Phẩm Cùng Nhà Sản Xuất'"
    [searchType]="'manufacturers'"
  >
  </app-related-products>
</section>

<app-product-detail-image-modal
  #productDetailImageModal
  [title]="product ? product.name : ''"
  [images]="product ? product.imageUrls : []"
  [active]="activeSlide"
  (currentSlide)="setSlide($event, owlCar)"
>
</app-product-detail-image-modal>
