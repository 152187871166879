<ng-template #productDetailImageModal let-modal>
  <div class="modal-content">
    <div class="modal-header p-0">
      <h3 class="title">{{ title }}</h3>
      <app-button
        [type]="'button'"
        [class]="'btn btn-close'"
        [id]="'product_detail_close_modal_btn'"
        [spinner]="false"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="ri-close-line"></i>
      </app-button>
    </div>
    <div class="modal-body">
      <div class="row g-sm-4 g-2">
        <div class="col-lg-12">
          <div class="view-image-slider">
            <owl-carousel-o [options]="productMainThumbSlider" #owlCar (changed)="getPassedData($event)" (initialized)="owlCar.to(this.activeSlide);">
              <ng-container *ngFor="let image of images; index as i">
                <ng-template carouselSlide [id]="i.toString()">
                  <div class="d-flex">
                    <img
                      [src]="image ? image : 'assets/images/product.png'"
                      class="img-fluid min-height"
                      alt="product"
                    />
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
      <div class="row g-sm-4 g-2" style="padding-top: 1rem;">
        <div class="col-12" *ngIf="images.length">
          <div class="bottom-slider-image left-slider no-arrow slick-top">
            <owl-carousel-o [options]="productThumbSlider">
              <ng-container *ngFor="let image of images; let i = index">
                <ng-template carouselSlide [id]="i.toString()">
                  <div>
                    <div
                      class="slider-image"
                      [class.active]="i.toString() == activeSlide.toString()"
                    >
                      <img
                        [src]="image ? image : 'assets/images/product.png'"
                        class="img-fluid img-slider"
                        [class]="activeSlide == i.toString() ? 'active' : ''"
                        [alt]="title"
                        (click)="setSlide(i); owlCar.to(activeSlide = i.toString());"
                      />
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
