import { Component, Input } from '@angular/core';
import { AdditionalInfo, Product } from '@/app/shared/interface/product.interface';

@Component({
  selector: 'app-product-details-tabs',
  templateUrl: './product-details-tabs.component.html',
  styleUrls: ['./product-details-tabs.component.scss']
})
export class ProductDetailsTabsComponent {

  @Input() product: Product;

  public active = '';

  constructor(){}

  ngOnInit() {
    this.active = this.product.additionalInfo[0].name;
  }
}
