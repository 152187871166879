import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as data from '@/app/shared/data/owl-carousel';
import { SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product-detail-image-modal',
  templateUrl: './product-detail-image-modal.component.html',
  styleUrl: './product-detail-image-modal.component.scss'
})
export class ProductDetailImageModalComponent {
  
  @Input() title: string;
  @Input() images: string[];
  @Input() active: string = "0";
  @Input() canSlide: boolean = true;
  @ViewChild("productDetailImageModal", {static: false}) productDetailImageModal: TemplateRef<any>;

  @Output() currentSlide: EventEmitter<string> = new EventEmitter();

  public modalOpen: boolean = false;
  public closeResult: string;

  public productMainThumbSlider = data.productMainThumbSlider;
  public productThumbSlider = data.categorySlider9;
  public activeSlide: string;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.productThumbSlider.items = this.images.length;
    this.productThumbSlider.dots = true;
    this.productThumbSlider.loop = false;
    this.activeSlide = this.active;

    if (!this.canSlide) {
      this.productMainThumbSlider.mouseDrag = false;
      this.productMainThumbSlider.touchDrag = false;
      this.productMainThumbSlider.pullDrag = false;
    }
  }

  ngOnChanges(change: any) {
    if (change.active) {
      this.activeSlide = change.active.currentValue;
    }
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.productDetailImageModal, {
        ariaLabelledBy: 'Product-Detail-Modal',
        centered: true,
        windowClass: 'theme-modal view-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getPassedData(data: SlidesOutputData) {
    if (data.slides!.length > 0) {
      this.activeSlide = data.startPosition!.toString();
      this.currentSlide.emit(this.activeSlide);
    }
  }

  setSlide(i: number) {
    this.activeSlide = i.toString();
    this.currentSlide.emit(this.activeSlide);
  }
}
