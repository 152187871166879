<ng-template #productDetailModal let-modal>
    <div class="modal-content">
        <div class="modal-header p-0">
            <app-button [type]="'button'" [class]="'btn btn-close'" [id]="'product_detail_close_modal_btn'" [spinner]="false" (click)="modal.dismiss('Cross click')">
                <i class="ri-close-line"></i>
            </app-button>
        </div>
        <div class="modal-body">
            <div class="row g-sm-4 g-2">
                <div class="col-lg-6">
                  <div class="view-image-slider">
                    <owl-carousel-o [options]="productMainThumbSlider" #owlCar>
                        <ng-container *ngFor="let image of product?.imageUrls; index as i">
                            <ng-template carouselSlide [id]="i.toString()">
                                <img [src]="( image ? image
                                        : 'assets/images/product.png')" class="img-fluid" alt="product">
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                  </div>
                  <div class="col-12" *ngIf="product?.imageUrls?.length">
                    <div class="bottom-slider-image left-slider no-arrow slick-top">
                        <owl-carousel-o [options]="productThumbSlider">
                            <ng-container *ngFor="let image of product?.imageUrls; let i = index">
                                <ng-template carouselSlide [id]="i.toString()">
                                    <div>
                                        <div class="slider-image" [class.active]="i.toString() == activeSlide.toString()">
                                            <img [src]="image ? image
                                                    : 'assets/images/product.png'" class="img-fluid" [alt]="product.name"
                                                    (click)="owlCar.to(activeSlide = i.toString())">
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
                </div>
                <div class="col-lg-6">
                    <div class="right-sidebar-modal">
                        <h4 class="title-name">{{ product.name }}</h4>
                        <!-- <h4 class="price">
                            {{ (selectedVariation ? selectedVariation.sale_price : product.sale_price) | currencySymbol }}
                            <del *ngIf="selectedVariation ? selectedVariation.discount : product.discount">
                                {{ (selectedVariation ? selectedVariation.price : product.price) | currencySymbol }}
                            </del>
                            <label class="modal-label" *ngIf="selectedVariation ? selectedVariation.discount : product.discount">
                                {{ (selectedVariation ? selectedVariation.discount : product.discount) }}% off
                            </label>
                        </h4> -->
                        <div class="product-rating">
                            <!-- <ngb-rating [rate]="product.rating_count"></ngb-rating> -->
                            <!-- <div class="fs-14 ms-2">{{ product.reviews_count }} review</div> -->
                        </div>
                        <div class="product-detail mb-2">
                            <h4>Chi tiết sản phẩm:</h4>
                            <p [innerHTML]="product.short_description"></p>
                        </div>
                        <div class="pickup-box">
                            <div class="product-info">
                                <ul class="product-info-list product-info-list-2">
                                    <li *ngIf="product.sku">SKU: {{ product.sku }}</li>
                                    <li *ngIf="product.stockStatus">Trạn thái: </li>
                                    <li *ngIf="product.quantityAvailable > 0">Số lượng: {{ product.quantityAvailable }} còn lại</li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="selection-section mt-3" *ngIf="product.attributes?.length">
                            <app-variant-attributes
                                [product]="product"
                                [attributes]="product.attributes || []"
                                [owlCar]="owlCar"
                                (selectVariation)="selectVariation($event!)">
                            </app-variant-attributes>
                        </div> -->
                        <div class="modal-button">
                            <div class="modal-qty">
                                <div class="input-group">
                                  <app-button
                                      [class]="'btn qty-left-minus'"
                                      [id]="'add-to-cart'+product.id"
                                      [spinner]="false"
                                      (click)="updateQuantity(-1)">
                                      <i class="ri-subtract-fill"></i>
                                  </app-button>
                                  <input class="form-control input-number qty-input" type="text" name="quantity" value="{{productQty}}" readonly>
                                  <app-button
                                      [class]="'btn qty-left-minus'"
                                      [id]="'qty-left-minus'+product.id"
                                      [spinner]="false"
                                      (click)="updateQuantity(1)">
                                      <i class="ri-add-line"></i>
                                  </app-button>
                                </div>
                            </div>
                            <app-button [id]="'addcartDetailsbtnVariation'+product.id"
                                [class]="'btn theme-bg-color view-button icon text-white fw-bold btn-md'"
                                (click)="addToCart(product)"
                                *ngIf="(product?.stockStatus == stockStatus && product?.type && product?.type === 'simple') else outStock">
                                    <i class="ri-shopping-cart-line me-1"></i> Thêm vào giỏ
                            </app-button>
                            <ng-template #outStock>
                                <app-button
                                    [class]="'btn theme-bg-color view-button icon text-white fw-bold btn-md'"
                                    [id]="'cartDetailsbtnOs'+product.id"
                                    [spinner]="false"
                                    [disabled]="true">
                                    {{ product.stockStatus == stockStatus ? 'Bán hết' : 'Thêm vào giỏ' }}
                                </app-button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
