import { Component, Input, TransferState } from '@angular/core';
import { Router } from '@angular/router';

import { CommonService } from '@/app/shared/services/common.service';
import { CartService } from '@/app/shared/services/cart.service';

import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { Option } from '@/app/shared/interface/common.interface';
import { Product, StockStatus } from '@/app/shared/interface/product.interface';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '@/app/shared/services/account.service';
import { QueryStringService } from '../../services/querystring.service';
import { LabelQueryString } from '../../filter';
import { AuthService, IS_ACCOUNT_ACTIVE, IS_AUTHENTICATED } from '../../services/auth.service';

@Component({
  selector: 'app-product-contain',
  templateUrl: './product-contain.component.html',
  styleUrls: ['./product-contain.component.scss'],
})
export class ProductContainComponent {
  @Input() product: Product;
  @Input() option: Option | null;
  @Input() owlCar: any;
  @Input() isAuthenticated: boolean = false;

  public cart: Cart;
  public cartItem: CartItem;
  public isAccountActivated: boolean = false;
  public ordersCount: number = 10;
  public viewsCount: number = 30;
  public labelQueryString : LabelQueryString = new LabelQueryString();
  public countsInterval: any;

  public tempporarilyUnavailable: StockStatus.TempporarilyUnavailable;

  body = {
    productId: 0,
    quantity: 1
  }

  constructor(
    private router: Router,
    private commonService: CommonService,
    private cartService: CartService,
    private toastService: ToastrService,
    private accountService: AccountService,
    private queryStringService: QueryStringService,
    private transferState: TransferState,
    private authService: AuthService
  ) { 
    
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isAccountActivated = this.authService.isAccountActivated();
    this.body.productId = this.product.productID;

    this.commonService.getCart.subscribe({
      next: (res: any) => {
        this.cart = res;
        this.setCartItem();
      },
    });

    this.commonService.getProductDetail.subscribe({
      next: (res: any) => {
        this.product = res;
        this.setCartItem();
      },
    });
  }

  setCartItem() {
    this.cartItem = this.cart?.items.find((item: CartItem) => item.productID == this.product.productID)!;
    if (this.cartItem) {
      this.commonService.setCartItem(this.cartItem);
    }
    else {
      this.cartItem = {
        imgUrl: this.product.imageUrls[0],
        name: this.product.name,
        price: this.product.basePrice,
        basePrice: this.product.basePrice,
        productID: this.product.productID,
        quantity: 0,
        labels: this.product.labels,
        SKU: this.product.SKU,
        slug: this.product.slug
      }
    }
  }

  checkStockAvailable() {
    
  }

  addToCart(item: any) {
    if (this.body.quantity == 0) {
      this.toastService.warning("Xin nhập số lượng", "", {positionClass: "toast-top-right"});
      return;
    }
    this.body.productId = item.productID;

    for (let cartItem of this.cart.items) {
      if (cartItem.productID == item.productID) {
        this.body.quantity = cartItem.quantity + 1;
        break;
      }
    }
    this.cartService.updateCart(JSON.stringify(this.body)).subscribe({
      next: (res: any) => {
        this.commonService.setCart(res);
        this.toastService.success('Đã cập nhật giỏ hàng!', "", {positionClass: "toast-top-right"});
      },
      error: (error: any) => {
        console.log(error);
        this.toastService.warning('Lỗi cập nhật giỏ hàng!', "", {positionClass: "toast-top-right"});
      }
    });
  }

  buyNow(product: any) {
    if (product) {
      
    }
  }

  addToWishlist(id: number) {
    
  }

  addToCompare(id: number) {
    
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    if (this.countsInterval) {
      clearInterval(this.countsInterval);
    }
  }

  redirectPage(slug : string) {
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug
      },
      queryParamsHandling: 'merge' // optional
    });
  }
}
