<div class="filter-title filter">
    <!-- <img src="assets/svg/filter 1.svg" />
    <h2 class="fs-4">Bộ lọc nhanh</h2> -->
    <a href="javascript:void(0);" (click)="clear()" *ngIf="dataFilter.length">Xóa hết bộ lọc</a>
</div>
<ul class="filter-value">
    <li *ngFor="let filter of dataFilter" (click)="remove(filter)">
        <button type="button" class="btn btn-outline-success btn-sm">{{filter['name']}}<i class="ri-close-line close-icon"></i></button>
        
    </li>
</ul>
