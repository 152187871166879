import { GiftExchangeInfo, Product, WholesalePriceInfo } from "./product.interface";
import { Tag } from "./tag.interface";

export interface CartModel {
    items: Cart[];
    total?: number;
}

export class Cart {
    id: number;
    items: CartItem[] = [];
    sumTotalPrice: number;
    finalPrice: number;
    sumPriceSavingAmount: number;
    sumVoucherSavingAmount: number;
    userInfo: UserInfo;
    note?: string;
    transportMethod?: string;
    paymentMethod?: string;
    voucherDiscountAmount: number;
    appliedVoucher: AppliedVoucher;
    totalPoint?: number;
}

export class AppliedVoucher {
    code: string;
    description: string;
    discountType: number;
    discountValue: number;
    maximumAmount: number;
    minimumSpentRequired: number;
    id: string;
    conditions: DiscountConditions;
    type: number;
    usesLimitPerUser: number;
    remainingUses: number;
}

export interface DiscountConditions {
    start: Date,
    end: Date,
    userRoles: [],
    excludedUserRoles: string[],
    productIds: number[],
    excludedProductIds: string[],
    categoryIds: number[],
    excludedCategoryIds: number[],
}

export class CartItem {
    imgUrl: string;
    name: string;
    price: number;
    basePrice: number;
    productID: number;
    quantity: number;
    totalPrice?: number;
    labels: Tag[];
    SKU: string;
    slug: string;
    wholesalePriceInfo?: WholesalePriceInfo;
    giftExchangeInfo?: GiftExchangeInfo;
    wholesalePrices?: WholesalePriceInfo[];
}

export interface CartAddOrUpdate {
    id: number | null;
    product: Product | null;
    product_id: number;
    // variation: Variation | null;
    // variation_id: number | null;
    quantity: number;
}

export class UserInfo {
    id: string;
    address: string;
    phone: string;
    userName: string;
    status: number;
}

export interface TransportMethod {
    name: string;
    codeTransport?: string;
    paymentTerm: PaymentMethod[];
}

export interface PaymentMethod {
    name: string;
    code: string;
}

export class AddUserOrderModel {
    cart: Cart;
    transport?: TransportMethod = undefined;
}