import { Component, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Product, StockStatus } from '@/app/shared/interface/product.interface';
import { Cart, CartAddOrUpdate } from '@/app/shared/interface/cart.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss']
})
export class ProductDetailModalComponent {

  @ViewChild("productDetailModal", { static: false }) productDetailModal: TemplateRef<any>;

  @Input() product: Product;

  cartItem$: Observable<Cart[]>;

  public closeResult: string;
  public modalOpen: boolean = false;

  public cartItem: Cart | null;
  public productQty: number = 1;
  // public selectedVariation: Variation | null;

  public activeSlide: string = '0';
  
  public productMainThumbSlider = {};
  public productThumbSlider = {};

  public stockStatus: StockStatus;

  constructor(private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastService: ToastrService) {
  }

  ngOnInit() {
    // this.cartItem$.subscribe(items => {
    //   this.cartItem = items.find(item => item.product.id == this.product.id)!;
    // });
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.productDetailModal, {
        ariaLabelledBy: 'Product-Detail-Modal',
        centered: true,
        windowClass: 'theme-modal view-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  selectVariation(variation: any) {
    // this.selectedVariation = variation;
  }

  updateQuantity(qty: number) {
    if( qty < 0){
      this.toastService.error('Vui lòng nhập số lượng hợp lệ!', "", {positionClass: "toast-top-right"});
      return;
    }
    if(1 > this.productQty + (qty)) return;
    this.productQty = this.productQty + (qty);
    this.checkStockAvailable();
  }

  checkStockAvailable() {
    // if(this.selectedVariation) {
    //   this.selectedVariation['stock_status'] = this.selectedVariation?.quantity < this.productQty ? 'out_of_stock' : 'in_stock';
    // } else {
    //   this.product.stockStatus  = this.product?.quantityAvailable < this.productQty ? 'out_of_stock' : 'in_stock';
    // }
  }

  addToCart(product: Product) {
    // if(product) {
    //   const params: CartAddOrUpdate = {
    //     id: this.cartItem && (this.selectedVariation && this.cartItem?.variation && 
    //       this.selectedVariation?.id == this.cartItem?.variation?.id) ? this.cartItem.id : null,
    //     product_id: product?.id!,
    //     product: product ? product : null,
    //     variation: this.selectedVariation ? this.selectedVariation : null,
    //     variation_id: this.selectedVariation?.id ? this.selectedVariation?.id! : null,
    //     quantity: this.productQty
    //   }
    // }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
}
