import { Component } from '@angular/core';

import { Params } from '@/app/shared/interface/core.interface';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { CatetoryQueryString } from '@/app/shared/filter';
import { CommonService } from '@/app/shared/services/common.service';

@Component({
  selector: 'app-collection-filter',
  templateUrl: './collection-filter.component.html',
  styleUrls: ['./collection-filter.component.scss']
})
export class CollectionFilterComponent {

  dataFilter: Params[] = [];

  private productCatetories: any[];
  constructor(
    private queryStringService: QueryStringService,
    private commonServie: CommonService,
  ) {
    this.commonServie.getCategories.subscribe(data => {
      this.productCatetories = data;
      this.getDataFilter();
    });
  }

  ngOnInit() {
    this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.getDataFilter();
    });
  }

  getDataFilter() {
    let dataQueryString = this.queryStringService.queryString.filter(e => e.isDataFilter).filter(e => e.value !== '');
    this.dataFilter.length = 0;
    for (const data of dataQueryString) {
      if (data instanceof CatetoryQueryString) {
        this.dataFilter = this.getSelectedCatetories([...data.originalValue]);
      }
    }
  }

  getSelectedCatetories(originalSlugs: string[], cats: any[] | undefined = undefined): any[] {
    if (!cats) {
      cats = this.productCatetories;
    }
    let result: any[] = [];
    if (cats) {
      for (const cat of cats) {
        let i = originalSlugs.indexOf(cat.slug);
        if (i >= 0) {
          result.push({
            'id': cat.slug,
            'name': cat.name,
          });
          originalSlugs.splice(i, 1);
        }
        if (cat.children.length) {
          result = result.concat(this.getSelectedCatetories(originalSlugs, cat.children));
        }
      }
    }
    return result;
  }

  remove(filter: Params) {
    let dataQueryString = this.queryStringService.queryString.filter(e => e.isDataFilter).filter(e => e.value !== '');

    for (const data of dataQueryString) {
      if (data.value.includes(filter['id'])) {
        data.removeValue(filter['id']);
      }
    }
    this.queryStringService.changeQueryString(dataQueryString);

  }

  clear() {
    let dataQueryString = this.queryStringService.queryString.filter(e => e.isDataFilter).filter(e => e.value !== '');
    for (const data of dataQueryString) {
      data.value = '';
    }
    this.queryStringService.changeQueryString(dataQueryString);
  }
}
