<!-- <div class="leftbox-overlay" (click)="closeCanvasMenu()"></div> -->
<div class="shop-left-sidebar">
  <div class="back-button" (click)="closeCanvasMenu()">
      <h3>
        <a href="javascript:void(0)" class="text-title" style="display: flex;">
          <img #icon src="assets\images\svg\turnback.svg" style="width: 6%; margin-right: 3px;"/>
          <span>{{ 'Quay Lại' | translate }}</span>
        </a>
      </h3>
  </div>
  <ng-content></ng-content>
  <app-skeleton-collection-sidebar *ngIf="skeletonLoader else content"></app-skeleton-collection-sidebar>
  <ng-template #content>
    <div class="accordion custome-accordion">
      <app-collection-category-filter [filter]="filter"></app-collection-category-filter>
      
    </div>
  </ng-template>
</div>
