import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, TransferState } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Breadcrumb } from "../interface/breadcrumb";
import { Affiliate, User } from "../interface/user.interface";
import { CommonService } from '@/app/shared/services/common.service';
import { DOCUMENT } from "@angular/common";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { TOKEN } from "./auth.service";
import { BaseResponse } from "../interface/common.interface";

@Injectable({
  providedIn: "root",
})

export class AccountService {

  private userData = new BehaviorSubject<User>(new User());
  public getUserData = this.userData.asObservable();
  setUserData(user: User) {
    this.userData.next(user);
  }

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: SsrCookieService,
    private transferState: TransferState
  ) { }

  getUser() {
    return this.http.get<User>(`${environment.baseURL}user`);
  }

  getUserSidebar() {
    return this.http.get(`${environment.baseURL}sidebar`);
  }

  checkUserExist(username: string) {
    return this.http.get<boolean>(`${environment.baseURL}user/validate-user-login?userName=${username}`);
  }

  updateUser(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  checkAccountActivated() {
    // const localStorage = this.document.defaultView?.localStorage;
    // let jwtToken = this.commonService.parseJwt(localStorage?.getItem("token")!);
    // const token = this.cookieService.get("token");
    const token = this.transferState.get(TOKEN, '');
    
    let jwtToken = this.commonService.parseJwt(token);
    let isAccountActivated = jwtToken?.Status == "Active" ? true : false;
    return isAccountActivated;
  }

  createUser(body: string) {
    return this.http.post<boolean>(`${environment.baseURL}user`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  createUserV2(body: string) {
    return this.http.post<boolean>(`${environment.baseURL}user/v1/create`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  // user/changepassword

  changepasswordUser(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user/changepassword`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  retrieveOTP(body: string) {
    return this.http.post<BaseResponse>(`${environment.baseURL}user/otp`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  submitOTP(body: string) {
    return this.http.post<boolean>(`${environment.baseURL}user/submit-otp`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  getAffiliate() {
    return this.http.get<Affiliate>(`${environment.baseURL}v1/affiliate`);
  }
}
