import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Ingredient, IngredientPaginationModel, Product, ProductModel, ProductsPaginationModel, RetriveUserModel } from "../interface/product.interface";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private http: HttpClient) { 
    
  }

  getProductsForGuestBCT() {
    return this.http.get<ProductModel>(`assets/data/ProductForGuest.json`)
  }

  getProducts(body: string) {
    return this.http.post<ProductModel>(`${environment.baseURL}retrieve-products-client`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getProductsForGuest(body: string) {
    return this.http.post<ProductModel>(`${environment.baseURL}retrieve-products-guest`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getProductsForMember(body: string) {
    return this.http.post<ProductModel>(`${environment.baseURL}retrieve-products-member`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  searchProducts(body: string){
    return this.http.post<ProductModel>(`${environment.baseURL}search-products-client`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  searchProductsForGuest(body: string){
    return this.http.post<ProductModel>(`${environment.baseURL}search-products-guest`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  searchProductsForMember(body: string){
    return this.http.post<ProductModel>(`${environment.baseURL}search-products-member`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getProductsPagination(body: any) {
    return this.http.post<ProductsPaginationModel>(`${environment.baseURL}retrieve-products-client`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getProductsPaginationforGuest(body: any) {
    return this.http.post<ProductsPaginationModel>(`${environment.baseURL}retrieve-products-guest`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getProductsPaginationForMember(body: any) {
    return this.http.post<ProductsPaginationModel>(`${environment.baseURL}retrieve-products-member`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getIngredientPagination(body: any) {
    return  this.http.post<IngredientPaginationModel>(`assets/data/ingredient-data.json`, body, {headers:{
      'Content-Type': 'application/json',
    }});
    };

  getIngredient(id: string){
    return this.http.get<Ingredient>(`assets/data/ingredient-data.json`)
  };

  getProductById(productId: number) {
    return this.http.get<Product>(`${environment.baseURL}product/${productId}`);
  }

  getProductBySlug(productSlug: string) {
    return this.http.get<Product>(`${environment.baseURL}product/slug/${productSlug}`);
  }
}
