import { Component, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2Data, Select2UpdateEvent, Select2Value } from 'ng-select2-component';
import { Subscription } from 'rxjs';
import { SortQueryString } from '../../filter';
import { QueryStringService } from '../../services/querystring.service';

@Component({
  selector: 'app-collection-sort',
  templateUrl: './collection-sort.component.html',
  styleUrls: ['./collection-sort.component.scss']
})
export class CollectionSortComponent {

  @Input() filter: any;
  @Input() gridCol: string;

  @Output() setGridClass: EventEmitter<any> = new EventEmitter();
  @Output() showFilter: EventEmitter<boolean> = new EventEmitter();

  private eventChangeQueryStringSubcription?: Subscription;

  public sortQuery: SortQueryString = new SortQueryString();

  public sorting: any = [{
    value: 'higher-price',
    label: 'Giá cao đến thấp',
    iconClasses: 'ri-sort-desc',
    selected: false,
  }, {
    value: 'lower-price',
    label: 'Giá thấp đến cao',
    iconClasses: 'ri-sort-asc',
    selected: false,
  }
  ];
  //[{value:'asc',label:'AscendingOrder',},{value:'desc',label:'DescendingOrder',},{value:'low-high',label:'Low-HighPrice',},{value:'high-low',label:'High-LowPrice',},{value:'a-z',label:'A-ZOrder',},{value:'z-a',label:'Z-AOrder',},{value:'discount-high-low',label:'%Off-HightToLow',}];

  public selectedGrid: string = "collection_4_grid";
  public class: string = "row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section";
  public gridArray = ['collection_3_grid', 'collection_4_grid', 'collection_5_grid', 'collection_list_view']

  isListView: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private queryStringService: QueryStringService) {
    this.setGridClass.emit(this.class);
    this.eventChangeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.sortQuery = this.queryStringService.getQueryString(new SortQueryString());
      this.checkSortSelected();
    });
  }

  ngOnInit() {

  }

  checkSortSelected() {
    for (const s of this.sorting) {
      if (s.value === this.sortQuery.value) {
        s.selected = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let layout = changes['filter']?.currentValue.layout;
    let gridCol = changes['gridCol']?.currentValue
    if (this.gridArray.includes(gridCol)) {
      this.selectedGrid = String(this.grid(gridCol))
    }

    if (this.gridArray.includes(layout)) {
      this.grid(layout);
    }
  }

  grid(value: string) {
    if (this.gridArray.includes(value)) {
      if (value == 'collection_3_grid') {
        this.class = "row g-sm-4 g-3 product-list-section row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2";
        this.isListView = false;
      }
      else if (value == 'collection_4_grid') {
        this.class = "row g-sm-4 g-3 product-list-section row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2";
        this.isListView = false;
      }
      else if (value == 'collection_5_grid') {
        this.class = "row g-sm-4 g-3 product-list-section row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2";
        this.isListView = false;
      }
      else if (value == 'collection_list_view') {
        this.class = "row g-sm-4 g-3 product-list-section row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style";
        this.isListView = true;
      }

      this.selectedGrid = value;
      this.setGridClass.emit({class: this.class, isListView :this.isListView});
    }
  }

  // SortBy Filter
  sortByButton(data: any) {
    for (const s of this.sorting) {
      if (data.value === s.value) {
        s.selected = !s.selected
      } else {
        s.selected = false;
      }
    }

    let selected = this.sorting.find((e: any) => e.selected);
    if (selected) {
      this.sortQuery.value = selected.value;
      this.queryStringService.changeQueryString(this.sortQuery);
    } else {
      this.sortQuery.value = '';
      this.queryStringService.changeQueryString(this.sortQuery);
    }
  }

  openOffCanvasMenu() {
    // this.attributeService.offCanvasMenu = true;
    this.showFilter.emit(true);
  }

  openFilter(value: boolean) {
    // this.attributeService.offCanvasMenu = value;
    this.showFilter.emit(true);
  }

}
