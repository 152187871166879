import { Role } from "./role.interface";
import { Country } from "./country.interface";
import { Attachment } from "./attachment.interface";
import { Point } from "./point.interface";
import { Province } from "./province.interface";

export class User {
    id: string;
    userName: string;
    email: string;
    phone: string;
    country_code: string;
    profile_image?: Attachment;
    profile_image_id?: number;
    status: number;
    email_verified_at: string;
    role_id: number;
    role_name?: string;
    role?: Role;
    address?: DeliveryAddress;
    point?: Point;
    orders_count: number;
    is_approved: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    businessName: string;
    businessType?: BusinessType;
    businessRegistrationCert: string[];
    businessRegistrationCert2: string[];
    district: string;
    doB: Date;
    fullName: string;
    createdBy: string;
    createdDate: Date;
    isStatus: number;
    lastModifiedDate: Date;
    modifiedBy: string;
    permissions: string;
    pharmaceuticalStandard: string[];
    province: string;
    referer: string;
    roles: UserRole[];
    ward: string;
    gender: number;
    taxNumber?: string;
    deliveryAddress: DeliveryAddress[];
    avatarUrl: string;
    isReviewRequested: boolean = false;
    giftPoint: number;
}

export enum BusinessType
{
    Drugstore = 0,
    Drugstores = 1,
    MedicineCabinet = 2,
    WholesaleFacility = 3,
    Hospital = 4,
    OtherMedicalTreatmentFacilities = 5,
    Other = 6
}

export interface DeliveryAddressModel {
    deliveryAddress: DeliveryAddress[];
    userName: string;
}

export class DeliveryAddress {
    id: string;
    address: string;
    province: Province;
    receiverName: string;
    receiverPhone: string;
    status: StatusAddress = 1;
}

export enum StatusAddress
{
    Default = 0,
    Active = 1,
    Inactive = 2
}

export interface UserRole {
    createdBy: string;
    createdDate: Date;
    id: string;
    isActive: boolean;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    roleName: string;
    permissions: number[];
}

export interface UserAddress {
    id: string;
    Address: string;
    ReceiverName: string;
    ReceiverPhone: string;
    Province: Province;
    Status: StatusAddress;
}

export class UserLite{
    id: string;
    userName: string;
}

export enum UserTier {
    "None" = 0,
    "Active" = 1,
    "Inactive" = 2,
    "Pending" = 3,
    "Tier1" = 4,
    "Tier2" = 5,
}

export interface PointUsage {
    point: number;
    usedDate: string;
    orderId: string;
    pointDetail: ItemPointUsage;
    userUpdate: string;
    xPickingPartner: string;
    branchCode: string;
    id: string;
    lastUpdate: string;
}

export interface ItemPointUsage {
    productId: number;
    quantity: number;
    point: number;
}

export interface Affiliate {
    availableAffiliatePoint: number;
    directAffiliatePoint: number;
    indirectAffiliatePoint: number;
    totalDirectPartner: number;
    totalIndirectPartner: number;
}