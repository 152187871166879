import { CatetoryQueryString } from '@/app/shared/filter';
import { Category } from '@/app/shared/interface/category.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { Component, EventEmitter, Input, Output, afterNextRender } from '@angular/core';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-collection-category-filter',
  templateUrl: './collection-category-filter.component.html',
  styleUrl: './collection-category-filter.component.scss'
})
export class CollectionCategoryFilterComponent {

  @Input() filter: Params;

  private changeQueryStringSubcription?: Subscription;

  public categoryQuery: CatetoryQueryString = new CatetoryQueryString();

  public categories: Category[] = [];
  private updatedFromView = false;
  constructor(
    private commonService: CommonService,
    private queryStringService: QueryStringService
  ) {
    this.changeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.categoryQuery = this.queryStringService.getQueryString(new CatetoryQueryString());
      if (this.updatedFromView === false) {
        this.updateSelectedCategory(this.categories);
      } else {
        this.updatedFromView = false;
      }
    });
  }

  ngOnInit() {
    this.categoryQuery = this.queryStringService.getQueryString(new CatetoryQueryString());
    this.commonService.getCategories.subscribe({
      next: (data: any) => {
        this.categories = data;
        this.updateSelectedCategory(this.categories);
      },
    });

  }

  applyFilter(category: any, i: number) {
    category.selected = !category.selected;
    this.updatedFromView = true;
    this.selectRecurive(this.categories);
    this.changeQueryString();
  }

  selectRecurive(categories: Category[], parent: Category | undefined = undefined) {
    if (categories.length) {
      for (const cat of categories) {
        if (cat.children && cat.children.length) {
          if (parent) {
            parent.childrenSelected = cat.children.some(p => p.selected);
          }
          this.selectRecurive(cat.children, cat);
        }
      }
    }
  }

  changeQueryString() {
    let s = this.getQueryString(this.categories);
    this.categoryQuery.value = s;
    this.queryStringService.changeQueryString(this.categoryQuery);
  }

  getQueryString(cat: Category[] | undefined) {
    let s = '';
    if (!cat || cat.length === 0) {
      return s;
    }
    for (const c of cat) {
      if (c.selected) {
        s += `${c.slug},`;
      }
      s += this.getQueryString(c.children);
    }
    return s;
  }

  updateSelectedCategory(cat: Category[] | undefined, parent: Category | undefined = undefined, queryStringValue: string[] | undefined = undefined) {
    if (typeof queryStringValue === 'undefined') {
      queryStringValue = [...this.categoryQuery.originalValue];
    }
    if (!cat || cat.length === 0) {
      return;
    }
    if (cat && cat.length) {
      for (const c of cat) {
        let i = queryStringValue.indexOf(c.slug);
        if (i >= 0) {
          c.selected = true;
          queryStringValue.splice(i, 1);
        } else {
          c.selected = false;
        }
        this.updateSelectedCategory(c.children, c, queryStringValue);
      }
      if (parent) {
        parent.childrenSelected = cat.some(p => p.selected);
      }
    }
  }

  checkAnyChildrenSelected(cat: Category) {
    let result = false;
    if (cat.children && cat.children.length) {
      result = cat.children.some(p => p.selected || this.checkAnyChildrenSelected(p));
    }
    return result;
  }
  openCloseCategory(cat: Category) {
    cat.isShow = !cat.isShow;
  }
  ngOnDestroy() {
    this.changeQueryStringSubcription?.unsubscribe();
  }

}

