<!-- <div class="product-count" *ngIf="(option?.product?.encourage_order && product?.encourage_order) ||
    (option?.product?.encourage_view && product?.encourage_view)">
    <ul>
        <li *ngIf="option?.product?.encourage_order && product?.encourage_order">
            <i class="ri-flashlight-line"></i>
            <h6 class="lang">{{ ordersCount }} {{ 'customers_ordered' | translate }}</h6>
        </li>
        <li *ngIf="option?.product?.encourage_view && product?.encourage_view">
            <i class="ri-eye-line"></i>
            <h6 class="lang">{{ viewsCount }} {{ 'active_view_in_this_item' | translate }}</h6>
        </li>
    </ul>
</div> -->
<h2 class="name">{{ product.name }}</h2>
<div
  class="price-rating"
  *ngIf="product.stockStatus != 8 && product.basePrice > 0
  "
>
  <h3
    class="{{
      product.pricingTablePrice < product.basePrice
        ? 'text-danger'
        : 'theme-color'
    }} price fw-bold d-flex align-items-center gap-3"
  >
    {{ product.pricingTablePrice | currencySymbol }}
    <del
      class="text-content"
      *ngIf="product.pricingTablePrice < product.basePrice"
    >
      {{ product.basePrice | currencySymbol }}
    </del>
    <span
      class="fw-bold text-danger bg-warning px-2 py-1 rounded-4"
      *ngIf="product.pricingTablePrice < product.basePrice"
    >
      {{
        -(product.basePrice - product.pricingTablePrice) / product.basePrice
          | percent
      }}
    </span>
  </h3>
  <!-- <div class="product-rating custom-rate">
        <ngb-rating [rate]="product.rating_count"></ngb-rating>
        <span class="review">{{ product.reviews_count }} {{ 'review' | translate }}</span>
    </div> -->
</div>
<div
  class="price-rating"
  *ngIf="isAuthenticated && product.basePrice <= 0"
>
  <a routerLink="/account/my-business-profile"><h3 *ngIf="product.stockStatus == 8" class="text-danger">Vui lòng hoàn thiện hồ sơ để xem giá</h3></a>
  <h3 *ngIf="product.stockStatus < 8" class="text-danger">Sản Phẩm tạm thời hết hàng</h3>
</div>

<div *ngIf="isAuthenticated && product.wholesalePrices?.length" class="wsp px-3 py-2 d-flex flex-wrap mt-2 gap-3">
  <ng-container *ngFor="let wsp of product.wholesalePrices; let i = index">
    <div>
      <h5>mua từ {{ wsp.minQuantity }}:</h5>
      <h5 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h5>
    </div>
    <div *ngIf="i < product.wholesalePrices.length - 1" class="my-1" style="border: 1px solid var(--theme-color);"></div>
  </ng-container>
</div>
<!-- <div *ngIf="product.labels.length > 0">
  <div class="sort-by-group">
    <ul class="list-inline">
      <li
        class="list-inline-item"
        *ngFor="let l of product.labels; let i = index"
      >
        <app-button
          [label]="l.name"
          id="btn-sort-{{ i }}"
          [class]="'btn btn-sm text-theme border border-success'"
          (click)="redirectPage(l.slug)"
          [type]="'button'"
        >
          <span>{{ l.name }}</span>
        </app-button>
      </li>
    </ul>
  </div>
</div> -->

<app-product-information
  [product]="product"
  [cartItem]="cartItem"
  [isAuthenticated]="isAuthenticated"
></app-product-information>
<!-- <div class="product-contain" *ngIf="product?.short_description">
    <p [innerHTML]="product.short_description"></p>
</div>
<div class="product-package">
    <app-variant-attributes [product]="product" [attributes]="product.attributes || []"
        (selectVariation)="selectVariation($event!)" [owlCar]="owlCar">
    </app-variant-attributes>
</div>
<app-sale-timer [startDate]="product.sale_starts_at" [endDate]="product.sale_expired_at"
    *ngIf="product.sale_starts_at && product.sale_expired_at">
</app-sale-timer> -->
<div class="note-box product-package">
  <!-- <div class="cart_qty qty-box product-qty">
    <div class="input-group">
      <app-counter *ngIf="cartItem" [cartItem]="cartItem" style="width: 100%;"></app-counter>
    </div>
  </div> -->
  <!-- <div class="wishlist-btn-group">
    <app-button
      [class]="'wishlist-button btn'"
      [id]="'wishlist_btn'"
      [spinner]="false"
      (click)="addToWishlist(product.productID)"
    >
      <i class="ri-heart-line"></i>
    </app-button>
    <app-button
      [class]="'wishlist-button btn'"
      [id]="'compare_btn'"
      [spinner]="false"
      (click)="addToCompare(product.productID)"
    >
      <i class="ri-refresh-line"></i>
    </app-button>
  </div> -->
</div>
<!-- <div class="dynamic-checkout">
  <app-button
    [class]="'btn btn-md bg-theme'"
    [id]="'buynowbtn' + product.productID"
    [disabled]="product.stockStatus != tempporarilyUnavailable ? false : true"
    (click)="buyNow(product)"
  >
    Mua ngay
  </app-button>
  <app-button
    [id]="'addcartbtnVariation' + product.productID"
    [class]="'btn btn-md border-theme-color scroll-button'"
    (click)="addToCart(product)"
    *ngIf="product.stockStatus != tempporarilyUnavailable; else outStock"
  >
    <i class="ri-shopping-cart-line me-1"></i> {{ "add_to_cart" | translate }}
  </app-button>
  <ng-template #outStock>
    <app-button
      [class]="'btn btn-md border-theme-color scroll-button disabled'"
      [id]="'soldoutbtnproductcontain'"
      [spinner]="false"
      [disabled]="true"
    >
      <i
        class="ri-shopping-cart-line me-1"
        *ngIf="product.stockStatus == tempporarilyUnavailable"
      ></i>
      {{
        (product.stockStatus == tempporarilyUnavailable ? "sold_out" : "add_to_cart")
          | translate
      }}
    </app-button>
  </ng-template>
</div> -->
