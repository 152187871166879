import { Component, EventEmitter, Inject, Input, Output, TransferState, ViewChild, afterNextRender } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CartService } from '@/app/shared/services/cart.service';
import { CommonService } from '@/app/shared/services/common.service';

import { ProductDetailModalComponent } from '@/app/shared/widgets/modal/product-detail-modal/product-detail-modal.component';
import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { Product } from '@/app/shared/interface/product.interface';
// import $ from 'jquery';
import { WishlistService } from '@/app/shared/services/wishlist.service';
import { AccountService } from '@/app/shared/services/account.service';
import { Tag } from '@/app/shared/interface/tag.interface';
import { DOCUMENT } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Router } from '@angular/router';
import { LabelQueryString, QueryString } from '@/app/shared/filter';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';


declare var $: any;

@Component({
  selector: 'app-product-box-horizontal',
  templateUrl: './product-box-horizontal.component.html',
  styleUrls: ['./product-box-horizontal.component.scss']
})
export class ProductBoxHorizontalComponent {

  @Input() product: Product;
  @Input() class: string;
  @Input() close: boolean;
  @Input() isListView: boolean = false;
  @Output() productLabel: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("productDetailModal") productDetailModal: ProductDetailModalComponent;

  public currentDate: number | null;
  public saleStartDate: number | null;
  public cart: Cart = new Cart();
  public cartItem: CartItem;
  public isAuthenticated: boolean;
  public isAccountActivated: any;
  public labelQueryString: LabelQueryString;

  inWishList: boolean = false;

  userTier: number = 1;

  constructor(
    config: NgbRatingConfig,
    private commonService: CommonService,
    private wishlistService: WishlistService,
    private toastService: ToastrService,
    private accountService: AccountService,
    private cookieService: SsrCookieService,
    private router: Router,
    private queryStringService: QueryStringService,
    private authService: AuthService,
    private transferState: TransferState,
    @Inject(DOCUMENT) private document: Document
  ) {
    afterNextRender(() => {
      if (!this.isListView) {
        $(".product-box-3")?.addClass("d-flex").addClass("flex-column").addClass("justify-content-between");
      }
      else {
        $(".product-box-3")?.removeClass("d-flex").removeClass("flex-column").removeClass("justify-content-between");
      }
    });
    config.max = 5;
    config.readonly = true;
  }

  public labels: Tag[];

  ngOnInit() {
    this.userTier = Number(this.authService.getUserTier());

    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      this.isAccountActivated = this.authService.isAccountActivated();
    }

    this.sortWholeSalePrices();

    this.cartItem = {
      imgUrl: this.product.imageUrls[0],
      name: this.product.name,
      price: this.product.basePrice,
      basePrice: this.product.basePrice,
      productID: this.product.productID,
      quantity: 0,
      labels: this.product.labels,
      SKU: this.product.SKU,
      slug: this.product.slug
    }
    this.labels = this.product.labels;

    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          if (this.cart && this.cart.items.length) {
            for (let item of this.cart.items) {
              if (item.productID == this.product.productID) {
                this.cartItem = item;
                break;
              }
            }
          }
        }
        else {
          this.cart = new Cart();
        }
      }
    });
    this.commonService.getWishlist.subscribe({
      next: (res: number[]) => {
        if (res && res.length > 0) {
          for (let id of res) {
            if (id == this.cartItem.productID) {
              this.inWishList = true;
            }
          }
        }
        else {
          this.inWishList = false;
        }
      }
    });
  }

  sortWholeSalePrices() {
    if (this.product.wholesalePrices?.length) {
      this.product.wholesalePrices = this.product.wholesalePrices.sort((a, b) => a.minQuantity - b.minQuantity);
    }
  }


  addToWishlist(id: number) {
    this.inWishList = !this.inWishList;
    const body = {
      productId: id
    }
    this.wishlistService.addWishlist(JSON.stringify(body)).subscribe({
      next: (res: number[]) => {
        if (res) {
          if (res.some(r => r == this.cartItem.productID)) {
            this.toastService.success('Thêm vào danh sách yêu thích!', "", { positionClass: "toast-top-right" });
          }
          else {
            this.toastService.success('Loại bỏ khỏi danh sách yêu thích!', "", { positionClass: "toast-top-right" });
          }
          this.commonService.setWishlist(res);
        }
      },
      error: (error: any) => {
        this.toastService.error('Không thể cập nhật danh sách yêu thích!', "", { positionClass: "toast-top-right" });
        console.log(error);
      }
    })
  }

  removeWishlist(id: number) {

  }

  addToCompar(id: number) {

  }

  queryStringBuilder(slug: string) {
    this.productLabel.emit(slug);
    // set label query string
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug
      },
      queryParamsHandling: '' // optional
    });
  }
}
