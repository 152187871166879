import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationModel, Product } from '@/app/shared/interface/product.interface';
import { CartItem } from '@/app/shared/interface/cart.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { QueryStringService } from '../../services/querystring.service';
import { Router } from '@angular/router';
import { WishlistService } from '../../services/wishlist.service';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product.service';
import { AccountService } from '@/app/shared/services/account.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent {

  @Input() product: Product;
  @Input() cartItem: CartItem;
  @Input() isAuthenticated: boolean = false;

  inWishList: boolean = false;
  isAccountActivated: boolean = false;
  constructor(
    private commonService: CommonService,
    private queryStringService: QueryStringService,
    private wishlistService: WishlistService,
    private toastService: ToastrService,
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    if(this.isAuthenticated) {
      this.isAccountActivated = this.authService.isAccountActivated();
    }
    this.commonService.getCategories.subscribe({
      next: (res: any) => {
        if (res) {
          let slugs = [];
          for (let category of this.product.categories) {
            const d = res.find((d: any) => d.categoryId == category.categoryId);
            if (d) {
              slugs.push(d.slug);
            }
          }
          this.product.productInfo.find(p => p.type == 0)!.path = "product?category=" + slugs.join(",");
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
    
    this.commonService.getWishlist.subscribe({
      next: (res: number[]) => {
        if (res && res.length > 0) {
          for (let id of res) {
            if (id == this.cartItem.productID) {
              this.inWishList = true;
            }
          }
        }
      }
    });
  }

  goto(link: string) {
    this.router.navigateByUrl(link);
  }

  addToWishlist(id: number) {
    this.inWishList = !this.inWishList;
    const body = {
      productId: id
    }
    this.wishlistService.addWishlist(JSON.stringify(body)).subscribe({
      next: (res: number[]) => {
        if (res) {
          if (res.some(r => r == this.cartItem.productID)) {
            this.toastService.success('Thêm vào danh sách yêu thích!', "", {positionClass: "toast-top-right"});
          }
          else {
            this.toastService.success('Loại bỏ khỏi danh sách yêu thích!', "", {positionClass: "toast-top-right"});
          }
          this.commonService.setWishlist(res);
        }
      },
      error: (error: any) => {
        this.toastService.error('Không thể cập nhật danh sách yêu thích!', "", {positionClass: "toast-top-right"});
        console.log(error);
      }
    })
  }

  redirectPage(slug : string) {
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug
      },
      queryParamsHandling: 'merge' // optional
    });
  }
}
