import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Cart, TransportMethod } from "../interface/cart.interface";
import { CommonService } from "./common.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CartService {

  private userTransportData = new BehaviorSubject<TransportMethod[]>(new Array<TransportMethod>);
  public getUserTransportData = this.userTransportData.asObservable();
  setUserTransportData(res: TransportMethod[]) {
    this.userTransportData.next(res);
  }

  constructor(private http: HttpClient,
    private commonService: CommonService) { }

  updateCart(body: string) {
    return this.http.post(`${environment.baseURL}cart`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateCartGift(body: string) {
    return this.http.post(`${environment.baseURL}cart-gift`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateCartMany(body: string) {
    return this.http.post(`${environment.baseURL}cart/many`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getCarts() {
    return this.http.get(`${environment.baseURL}cart`);
  }

  applyVoucher(body: string) {
    return this.http.post<Cart>(`${environment.baseURL}apply-voucher`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  removeVoucher() {
    return this.http.post<Cart>(`${environment.baseURL}remove-applied-voucher`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  refreshCart() {
    this.getCarts().subscribe({
      next: (res: any) => {
        if (res) {
          this.commonService.setCart(res);
        }
      }
    });
  }

  getTranferMethods() {
    if (!this.userTransportData.getValue().length) {
      this.http.get<TransportMethod[]>(`${environment.baseURL}transport/erp/get-transport`).subscribe({
        next: (res: any) => {
          if (res) {
            this.setUserTransportData(res);
          }
        }
      });
    }
  }
}
