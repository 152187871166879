<h2 class="fs-4 pb-4">Nhóm danh mục</h2>

<ul class="category-list custom-padding" *ngIf="categories?.length">
    <div *ngTemplateOutlet="recursiveMenu; context: {$implicit: categories}">
    </div>
    <ng-template #recursiveMenu let-cates>
        <ng-container *ngFor="let category of cates; let i = index">
            <li *ngIf="category.isActive">
                <div class="form-check category-list-box" [class.has-children]="category.children.length">
                    <i *ngIf="category.children.length"
                        class="dv-chervon ri-arrow-right-s-line cursor-pointer"
                        [ngClass]="{'chervon-down': (category.selected || checkAnyChildrenSelected(category) || category.isShow)}"
                        (click)="openCloseCategory(category)"></i>
                    <input class="checkbox_animated" type="checkbox" id="category-{{category.slug}}-{{category.categoryId}}"
                        [value]="category.slug" [checked]="category.selected" (change)="applyFilter(category, i)">
                    <label class="form-check-label" for="category-{{category.slug}}-{{category.categoryId}}">
                        <span class="name">{{ category.name }}</span>
                    </label>
                </div>
                <ul class="category-list-children"
                    [class.show]="(category.children && (category.selected || checkAnyChildrenSelected(category) || category.isShow))">
                    <ng-container *ngTemplateOutlet="recursiveMenu; context: {$implicit: category.children}">
                    </ng-container>
                </ul>
            </li>
        </ng-container>
    </ng-template>
</ul>