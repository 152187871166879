import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from "@angular/core";
import { environment } from "@/environments/environment";
import { CommonService } from "./common.service";
import { Observable, catchError, of, switchMap } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { jwtDecode } from 'jwt-decode';
import { SignalrService } from "./notification.service";
import { User, UserTier } from "../interface/user.interface";
import { AccountService } from "./account.service";
import { CartService } from "./cart.service";
import { Cart, TransportMethod } from "../interface/cart.interface";

export const DEFAULT_VERSION = '1.0.0';

export const CURRENT_VERSION = makeStateKey<string>('currentVersion');
export const LATEST_VERSION = makeStateKey<string>('latestVersion');
export const IS_AUTHENTICATED = makeStateKey<boolean>('isAuthenticated');
export const TOKEN = makeStateKey<string>('token');
export const REFRESH_TOKEN = makeStateKey<string>('refreshToken');
export const IS_ACCOUNT_ACTIVE = makeStateKey<boolean>('isAccountActive');
export const COOKIES = makeStateKey<string>('cookies');
export const USERNAME = makeStateKey<string>('username');

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private res: any;
  constructor(private http: HttpClient, private commonService: CommonService,
    private toast: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: SsrCookieService,
    private transferState: TransferState,
    private notificationService: SignalrService,
    private accountService: AccountService,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  login(body: string) {
    return this.http.post(`${environment.baseURL}session`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).subscribe({
      next: (res: any) => {
        if (res) {
          this.transferState.set(IS_AUTHENTICATED, true);
          this.transferState.set(TOKEN, res.jwtToken);
          this.transferState.set(REFRESH_TOKEN, res.refreshToken);
          this.transferState.set(USERNAME, res.userName);

          const jwt = jwtDecode(res.jwtToken) as any;
          const isAccountActivated = jwt.Status === 'Active';
          this.transferState.set(IS_ACCOUNT_ACTIVE, isAccountActivated);

          this.setCookie('token', res.jwtToken);
          this.setCookie('username', res.userName);
          this.setCookie('refreshToken', res.refreshToken);
          this.setCookie(
            'user',
            JSON.stringify({
              username: res.userName,
              role: res.role,
            })
          );

          this.commonService.authEvent.emit(true);
          this.router.navigateByUrl('/quick-order', { replaceUrl: true });
          this.modalService.dismissAll();
        }
      },
      error: (error) => {
        console.log(error);
        this.toast.error("Sai Username hoặc Password!");
      },
    });

  }

  logout() {
    this.notificationService.stopConnection();
    this.clearSession();
    this.commonService.authEvent.emit(false);
  }

  isAuthenticated(): boolean {
    return this.transferState.get(IS_AUTHENTICATED, false);
  }

  isAccountActivated(): boolean {
    return this.transferState.get(IS_ACCOUNT_ACTIVE, false);
  }
  
  getUserTier() {
    try {
      const jwt = jwtDecode(this.transferState.get(TOKEN, '')) as any;
      return UserTier[jwt.Status];
    }
    catch (error) {
      //console.error('Error parsing access token payload', error);
      return UserTier.None;
    }
  }

  getAccessToken(): string | null {
    // return this.cookieService.get('token');
    return this.transferState.get(TOKEN, '');
  }

  isAccessTokenExpiredOrCloseToExpiry(): boolean {
    const token = this.getAccessToken();

    if (!token) {
      // If there is no token, consider it expired
      return true;
    }

    const expirationTime = this.getAccessTokenExpirationTime(token);
    if (!expirationTime) {
      // If there is an issue with the token, consider it expired
      return true;
    }

    // Calculate the remaining time until expiration in milliseconds
    const currentTime = new Date().getTime();
    const timeUntilExpiry = expirationTime - currentTime;

    // Check if the token is expired or close to expiry (5 minutes)
    return timeUntilExpiry < 0 || timeUntilExpiry < 5 * 60 * 1000;
  }

  //use as the old refreshToken, since the refreshToken is changed.
  //only use for the interceptor, D9 don't know what the fuck is this, so keep it
  refreshTokenInterceptor(refreshToken: string) {
    return this.http.post(`${environment.baseURL}refresh-token`, refreshToken, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }

  getLatestVersion() {
    return this.http.get<string>(`${environment.baseURL}version/`);
  }

  clearSession() {
    this.cookieService.delete('user');
    this.cookieService.delete('username');
    this.cookieService.delete('token');
    this.cookieService.delete('refreshToken');

    this.transferState.remove(IS_AUTHENTICATED);
    this.transferState.remove(IS_ACCOUNT_ACTIVE);
    this.transferState.remove(TOKEN);
    this.transferState.remove(REFRESH_TOKEN);

    this.accountService.setUserData(new User());
    this.cartService.setUserTransportData( new Array<TransportMethod>);
    this.commonService.setCart(new Cart());
  }

  refreshToken(refreshToken: string) {
    return this.http.post(`${environment.baseURL}refresh-token`, refreshToken, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).pipe(
      switchMap((res: any) => {
        if (res) {
          this.transferState.set(IS_AUTHENTICATED, true);
          this.transferState.set(TOKEN, res.jwtToken);
          this.transferState.set(REFRESH_TOKEN, res.refreshToken);

          const jwt = jwtDecode(res.jwtToken) as any;
          const isAccountActivated = jwt.Status === 'Active';
          this.transferState.set(IS_ACCOUNT_ACTIVE, isAccountActivated);

          this.setCookie('token', res.jwtToken);
          this.setCookie('username', res.userName);
          this.setCookie('refreshToken', res.refreshToken);
          this.setCookie(
            'user',
            JSON.stringify({
              username: res.userName,
              role: res.role,
            })
          );
          // this.commonService.authEvent.emit(true);
        }
        else {
          this.logout();
          this.commonService.setShowLoginPopup(true);
        }
        return of(res);
      }),
      catchError((err) => {
        this.logout();
        this.commonService.setShowLoginPopup(true);
        return of(err);
      })
    ).subscribe({
      next: (res: any) => {

      },
      error: (error) => {
        console.log(error);
        this.logout();
        this.commonService.setShowLoginPopup(true);
      },
    });;
  }

  updateToken(jwtToken: string, refreshToken: string): void {
    this.setCookie('token', jwtToken);
    this.setCookie('refreshToken', refreshToken);
    this.transferState.set(TOKEN, jwtToken);
  }

  private getAccessTokenExpirationTime(token: string): number | null {
    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      return tokenPayload.exp * 1000; // Convert seconds to milliseconds
    } catch (error) {
      console.error('Error parsing access token payload', error);
      return null;
    }
  }

  setCookie(name: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = navigator.userAgent;
      const isSafari =
        (userAgent.match(/(iPod|iPhone|iPad)/) && userAgent.match(/AppleWebKit/)) ||
        /^((?!chrome|android).)*safari/i.test(userAgent);

      if (isSafari) {
        this.setCookieForSafari(name, value);
      } else {
        const startDate = new Date();
        const expiration = new Date(startDate.setDate(startDate.getDate() + 7));
        this.cookieService.set(name, value, expiration, '/', '', true, 'Strict');
      }
    }
  }

  setCookieForSafari(name: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      var startDate = new Date();
      const expiration = new Date(startDate.setDate(startDate.getDate() + 7));
      var cookies = this.transferState.get(COOKIES, '');
      if (cookies) {
        var nameEQ = name + '=';
        var ca = cookies.split(';');
        if (ca) {
          var c = ca.find((c) => c.indexOf(nameEQ) != -1);

          if (c) {
            var cc = c.split('=');
            cc[1] = value;
            document.cookie = name + '=; max-age=-999999';
            document.cookie = name +
              '=' +
              value +
              '; path=/; expires=' +
              expiration.toUTCString();
          } else {
            document.cookie =
              name +
              '=' +
              value +
              '; path=/; expires=' +
              expiration.toUTCString();
          }
        }
      }
    }
  }
}

