<div class="product-section-box mt-0">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav nav-tabs custom-nav"
  >
    <li *ngFor="let tab of product.additionalInfo" [ngbNavItem]="tab.name">
      <a ngbNavLink *ngIf="tab.display"><h5>{{ tab.name | translate }}</h5></a>
      <ng-template ngbNavContent>
        <div class="product-description">
          <div
            class="nav-desh ckeditor-content"
            [innerHTML]="tab.value"
          ></div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="custom-tab"></div>
</div>
