<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<section class="section-b-space shop-section" id="filtered_products">
  <div class="container-fluid-lg">
    <div class="row">
      <div class="filter" (click)="showSideBar(false)">
        <app-collection-filter></app-collection-filter>
      </div>
      <div class="col-custome-3">
        <div class="left-box" [class.show]="offCanvasMenu">
          <app-collection-sidebar [filter]="filter" (closeSideBar)="showSideBar($event)"></app-collection-sidebar>
        </div>
      </div>
      <div [class]="'col-custome-9'">
        <app-collection-sort
          [filter]="filter"
          [gridCol]="gridCol"
          (setGridClass)="setGridClass($event)"
          (showFilter)="showSideBar($event)"
        ></app-collection-sort>
        <div [class]="gridClass" *ngIf="products.length && products.length > 0">
          <ng-container *ngIf="skeletonLoader && !products.length; else productContent">
            <app-skeleton-product-box
              *ngFor="let item of skeletonItems"
            ></app-skeleton-product-box>
          </ng-container>
          <ng-template #productContent>
            <ng-container *ngFor="let product of products">
              <app-product-box
                [product]="product"
                [isListView]="isListView"
                [class]="'product-box-3'"
                [style]="'horizontal'"
                (productLabel)="queryStringBuilder($event)"
              ></app-product-box>
            </ng-container>
          </ng-template>
        </div>
        <div class="row product-list-section mt-2 mb-2" *ngIf="!skeletonLoader && productsExtend.length && productsExtend.length > 0">
          <span class="fw-bold fs-4">Có thể bạn đang tìm</span>
        </div>
        <div [class]="gridClass">
          <ng-container *ngIf="skeletonLoader && !productsExtend.length; else productContentExtend">
            <app-skeleton-product-box
              *ngFor="let item of skeletonItems"
            ></app-skeleton-product-box>
          </ng-container>
          
          <ng-template #productContentExtend>
            <ng-container *ngFor="let product of productsExtend">
              <app-product-box
                [product]="product"
                [isListView]="isListView"
                [class]="'product-box-3'"
                [style]="'horizontal'"
                (productLabel)="queryStringBuilder($event)"
              ></app-product-box>
            </ng-container>
          </ng-template>
        </div>
        <app-no-data 
          [class]="'no-data-added collection-no-data'" 
          [image]="'assets/svg/no-product.svg'"
          [text]="'Không tìm thấy sản phẩm nào!'" 
          [description]="'Bạn hãy thử tìm lại với từ khóa khác nhé!'" 
          *ngIf="(products?.length == 0 && productsExtend?.length == 0 ) && !skeletonLoader">
        </app-no-data>
        <app-collection-paginate>
        </app-collection-paginate>
      </div>
    </div>
  </div>
</section>
