<div class="title title-flex-2" [class]="class" *ngIf="style === 'simple' && title; else classic">
  <h2>{{ title }}</h2>
  <ng-content></ng-content>
</div>
<ng-template #classic>
  <div [class]="class" *ngIf="title">
    <div>
      <h3>{{ title }}</h3>
      <!-- <span class="title-leaf">
        <svg class="icon-width">
          <use attr.xlink:href="assets/svg/leaf.svg#{{svg}}"></use>
        </svg>
      </span> -->
      <p *ngIf="description">{{ description }}</p>
    </div>
  </div>
</ng-template>
