import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '@/app/shared/interface/product.interface';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {
  
  @Input() product: Product;
  @Input() style: string  = 'horizontal';
  @Input() class: string;
  @Input() close: boolean = false;
  @Input() isListView: boolean = false;
  @Output() productLabel: EventEmitter<any> = new EventEmitter<any>();


  queryStringBuilder(slug: string) {
    this.productLabel.emit(slug);
  }
}
